import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="offcanvas"
export default class extends Controller {

  connect() {
    this.element.addEventListener("show.bs.offcanvas", () => {
      const content = document.querySelector(".main-content");
      content.style.width = `calc(100% - 32%)`;
    });

    this.element.addEventListener("hide.bs.offcanvas", () => {
      const content = document.querySelector(".main-content");
      content.style.width = "100%";
    });
  }
}
