import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="select"
export default class extends Controller {
  static values = {
    selected: { type: Array, default: [] },
    showSearch: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: true }
  };

  connect() {
   this.select = new SlimSelect({
      select: `#${this.element.id}`,
      data: this.options,
      settings: {
        showSearch: this.showSearchValue,
        closeOnSelect: this.closeOnSelectValue,
      },
    });

    this.select.setSelected(this.selectedValue);
  }

  get options() {
    return Array.from(this.element.options).map((option) => {
      return { text: option.text, value: option.value };
    });
  }
}
