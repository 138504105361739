import { Controller } from "@hotwired/stimulus"
import Tooltip from 'bootstrap/js/src/tooltip'

export default class extends Controller {
  static targets = ["enabled"]

  connect() {
  var tooltipList = this.enabledTargets.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
  }

  disconnect() {
    this.enabledTargets.forEach(function (tooltipEl) {
      Tooltip.getOrCreateInstance(tooltipEl).dispose()
    })
  }
}
