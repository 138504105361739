import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["toast"]

  connect() {
    if (this.hasToastTarget) {
      this.toastTargets.forEach(toast => {
        new Toast(toast).show()
      })
    }
  }
}
